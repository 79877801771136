<template>
  <b-card id="cleanings-planning-card">
    <!-- WEEK SELECTOR -->
    <b-row class="d-flex justify-content-between mb-2">
      <b-col cols="12" sm="auto" class="pt-25 font-weight-bold">
        <h4>
          {{ weekText }}
        </h4>
      </b-col>
      <b-col
        cols="12"
        sm="auto"
        class="d-flex justify-content-center mt-2 mt-sm-0"
      >
        <v-select
          v-model="provider"
          :options="providerOptions"
          :reduce="(option) => option.value"
          class="days-selector mr-1"
          style="min-width: 200px"
        />
        <v-select
          v-model="selectedDaysNumber"
          :options="selectedDaysOptions"
          :reduce="(option) => option.value"
          class="days-selector mr-1"
        />
        <b-button-group size="sm" class="w-100">
          <b-button
            v-b-tooltip.hover.bottom="'Període anterior'"
            variant="secondary"
            :disabled="loadingPlanning"
            @click="previousWeek"
          >
            <feather-icon icon="ChevronLeftIcon" />
          </b-button>
          <b-button
            v-b-tooltip.hover.bottom="'Avui'"
            variant="secondary"
            :disabled="loadingPlanning"
            @click="setToday"
          >
            <feather-icon icon="CircleIcon" />
          </b-button>
          <b-button
            v-b-tooltip.hover.bottom="'Període següent'"
            variant="secondary"
            :disabled="loadingPlanning"
            @click="nextWeek"
          >
            <feather-icon icon="ChevronRightIcon" />
          </b-button>
        </b-button-group>
      </b-col>
    </b-row>

    <!-- BOOKINGS TABLE -->
    <b-row>
      <b-col cols="12">
        <cleanings-planning-table
          :bookings="parsedCleaningsPlanning"
          :loading="loadingPlanning"
        />
      </b-col>
    </b-row>

    <!-- EXPORT LIST FOR WHATSAPP -->
    <b-row class="mb-1 d-flex justify-content-between justify-content-sm-end">
      <b-col cols="12" sm="auto">
        <b-button
          block
          variant="primary"
          @click="$copyText(whatsAppPlanningText)"
          :disabled="whatsAppButtonDisabled"
        >
          Copia planning per WhatsApp
        </b-button>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BButtonGroup,
  BButton,
  VBTooltip,
} from "bootstrap-vue";
import {
  formatDateObjectToDatabaseDate,
  formatDateObjectToDate,
  formatDateStringToDate,
} from "@/utils/formatters";
import {
  getBabyChairsText,
  getBabyCotsText,
  getSimpleGuestsText,
  notifyError,
} from "@/utils/methods";
import CleaningsPlanningTable from "@/views/bookings/cleanings/components/CleaningsPlanningTable.vue";
import _debounce from "lodash/debounce";
import vSelect from "vue-select";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    CleaningsPlanningTable,
    BButtonGroup,
    BButton,
    vSelect,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      weekStart: this.$moment().startOf("day"),
      selectedDaysNumber: 7,
      selectedDaysOptions: [
        { label: "1 dia", value: 1 },
        { label: "3 dies", value: 3 },
        { label: "7 dies", value: 7 },
        { label: "14 dies", value: 14 },
        { label: "30 dies", value: 30 },
        { label: "90 dies", value: 90 },
      ],
      provider: null,
    };
  },
  computed: {
    loadingPlanning() {
      return this.$store.getters["bookings/loadingPlanning"];
    },
    cleaningsPlanning() {
      if (this.loadingPlanning) {
        return [];
      }

      const checkinsPlanning = this.$store.getters["bookings/checkinsPlanning"];

      return checkinsPlanning.filter((booking) => {
        if (!booking.contract?.cleaningProvided) return false;

        let providerFilterPassed = true;
        
        if (this.provider) {
          providerFilterPassed = booking.contract.cleaningProviders.some(
            (cleaningProvider) => cleaningProvider.provider.uuid === this.provider
          );
        }

        const startDate = this.$moment(this.weekStart).startOf("day");
        const checkin = this.$moment(booking.checkin).startOf("day");

        return providerFilterPassed && checkin.isSameOrAfter(startDate);
      });
    },
    parsedCleaningsPlanning() {
      if (this.cleaningsPlanning.length === 0) return null;

      return this.cleaningsPlanning.map((booking) => {
        return {
          uuid: booking.uuid || this.$t("No definit"),
          date: new Date(booking.date) || this.$t("No definit"),
          localizator: booking.localizator || this.$t("No definit"),
          source: booking.source || null,
          guests: this.bookingGuests(booking) || this.$t("No definit"),
          accommodation: booking.accommodation?.name || this.$t("No definit"),
          accommodationUuid: booking.accommodation?.uuid || null,
          client: booking.client?.fullName || this.$t("No definit"),
          checkin: new Date(booking.checkin) || this.$t("No definit"),
          checkinTime:
            booking.details?.accommodationArrivalTime || this.$t("No definit"),
          checkout: new Date(booking.checkout) || this.$t("No definit"),
          checkoutTime:
            booking.details?.accommodationDepartureTime ||
            this.$t("No definit"),
          babyChairs: booking.details?.babyChairs || null,
          babyChairsText: this.bookingBabyChairs(booking),
          babyCots: booking.details?.babyCots || null,
          babyCotsText: this.bookingBabyCots(booking),
          highlighted: booking.highlighted || false,
          cleaningProviders: booking.contract?.cleaningProviders || [],
        };
      });
    },
    weekStartDate() {
      return this.weekStart.toDate();
    },
    weekStartText() {
      const formatting = {
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
      };

      return formatDateObjectToDate(
        this.weekStartDate,
        this.$i18n.locale,
        formatting
      );
    },
    weekEndDate() {
      return this.$moment(this.weekStartDate)
        .add(this.selectedDaysNumber - 1, "days")
        .toDate();
    },
    weekEndText() {
      const formatting = {
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
      };

      return formatDateObjectToDate(
        this.weekEndDate,
        this.$i18n.locale,
        formatting
      );
    },
    weekText() {
      if (this.selectedDaysNumber === 1) {
        return `Dia ${this.weekStartText}`;
      } else if (this.selectedDaysNumber === 7) {
        return `Setmana del ${this.weekStartText} al ${this.weekEndText}`;
      } else {
        return `Del ${this.weekStartText} al ${this.weekEndText}`;
      }
    },
    whatsAppPlanning() {
      if (!this.cleaningsPlanning.length) return [];
      const planning = {};
      this.cleaningsPlanning.forEach((booking) => {
        const bookingCheckin = booking.checkin.split("T")[0];

        if (!planning[bookingCheckin]) {
          planning[bookingCheckin] = [];
        }

        planning[bookingCheckin].push(booking);
      });
      return planning;
    },
    whatsAppPlanningText() {
      const keys = Object.keys(this.whatsAppPlanning);

      if (!keys.length) return;

      const text = [];

      for (let i = 0; i < keys.length; i++) {
        // Get the key and value of the current planning day
        const planningDay = keys[i];
        const planningDayBookings = this.whatsAppPlanning[planningDay];

        // Create the text for each day in the planning
        const planningDayText = this.getPlanningDayText(
          planningDay,
          planningDayBookings
        );

        // Push the planning day text to the global text
        text.push(planningDayText);
      }

      return text.length ? text.join("\r\n\r\n") : null;
    },
    whatsAppButtonDisabled() {
      return !Object.keys(this.whatsAppPlanning).length;
    },
    loadingProviders() {
      return this.$store.getters["providers/loadingProviders"];
    },
    providers() {
      return this.$store.getters["providers/providers"];
    },
    providerOptions() {
      return this.providers
      .filter((provider) => /loto|janer/i.test(provider.fullName))
      .map((provider) => ({
        label: provider.fullName,
        value: provider.uuid,
        uuid: provider.uuid,
      }));
    },
  },
  created() {
    this.fetchPlanning(this);
  },
  mounted() {
    if (!this.providers?.length && !this.loadingProviders) {
      this.fetchProviders();
    }
  },
  watch: {
    weekStart() {
      this.fetchPlanning(this);
    },
    selectedDaysNumber() {
      this.fetchPlanning(this);
    },
  },
  methods: {
    fetchPlanning: _debounce((innerThis) => {
      innerThis.$store
        .dispatch("bookings/fetchPlanning", {
          days: innerThis.selectedDaysNumber,
          startDate: formatDateObjectToDatabaseDate(
            innerThis.weekStart.toDate()
          ),
        })
        .catch(() => {
          notifyError(
            innerThis.$t("errors.fetchBooking.title"),
            innerThis.$t("errors.fetchBooking.description")
          );
        });
    }, 1000),
    fetchProviders() {
      this.$store
        .dispatch("providers/fetchProviders", {
          pagination: false,
          sort: "asc",
        })
        .then(() => {
          const janerProvider = this.providerOptions.find((provider) =>
            /janer/i.test(provider.label)
          );
          this.provider = janerProvider?.value || null;
        })
        .catch(() =>
          notifyError(
            "Error",
            "Hi ha hagut un error al intenter carregar els proveïdors"
          )
        );
    },
    bookingBabyChairs(booking) {
      if (!booking || !booking.details || booking.details.babyChairs == null)
        return `? ${this.$t("trones")}`;
      return getBabyChairsText(booking.details.babyChairs, this.$i18n.locale);
    },
    bookingBabyCots(booking) {
      if (!booking || !booking.details || booking.details.babyCots == null)
        return `? ${this.$t("cunes")}`;
      return getBabyCotsText(booking.details.babyCots, this.$i18n.locale);
    },
    bookingGuests(booking) {
      return getSimpleGuestsText({
        adults: booking.adults,
        children: booking.children,
        babies: booking.babies,
      });
    },
    nextWeek() {
      // ! We have to create a new momentjs object, otherwise Vue won't detect the changes.
      this.weekStart = this.$moment(this.weekStart).add(
        this.selectedDaysNumber,
        "days"
      );
    },
    previousWeek() {
      // ! We have to create a new momentjs object, otherwise Vue won't detect the changes.
      this.weekStart = this.$moment(this.weekStart).subtract(
        this.selectedDaysNumber,
        "days"
      );
    },
    setToday() {
      this.weekStart = this.$moment().startOf("day");
    },
    getPlanningDayBookingText(booking) {
      // Accommodation name
      const text = [`*🏠 ${booking.accommodation?.name || "XXXXXXXXXXXX"}*`];

      // Checkin time
      const checkinTime = booking.details?.accommodationArrivalTime || null;
      text.push(
        `- Hora d'entrada: ${checkinTime ? checkinTime + "h" : "16:00h"}`
      );

      // Checkout
      const checkoutText = [formatDateStringToDate(booking.checkout)];
      const checkoutTime =
        booking.details?.accommodationDepartureTime || "10:00";
      if (checkoutTime) checkoutText.push(checkoutTime + "h");
      text.push(`- Sortida: ${checkoutText.join(" ")}`);

      // Nights
      text.push(`- Vespres: ${booking.nights}`);

      // Linnen change
      const needChange = booking.nights > 8;
      let changeDate = this.incrementDays(
        new Date(booking.checkin),
        Math.ceil(booking.nights / 2)
      );
      if (booking.nights > 14) {
        changeDate = this.incrementDays(new Date(booking.checkin), 7);
      }
      text.push(
        `- Canvi de roba: ${
          needChange ? formatDateObjectToDate(changeDate) : "No"
        }`
      );

      // Guests
      const adults = booking.adults || 0;
      const children = booking.children || 0;
      const babies = booking.babies || 0;
      text.push(`- Hostes: ${adults + children + babies}`);

      // Cots and Chairs
      const cots = booking.details?.babyCots ?? babies;
      text.push(`- Cunes: ${cots === 0 ? "No" : cots}\r\n`);

      return text.length ? text.join("\r\n") : "";
    },
    getPlanningDayText(day, bookings) {
      // Create the first line of the planning day
      const text = [`*➡️ ENTRADES ${formatDateStringToDate(day)} ⬅️*`];

      // Create an entry for each booking in the current planning day
      const bookingsText = [];
      bookings.forEach((booking) => {
        bookingsText.push(this.getPlanningDayBookingText(booking));
      });

      // Push the bookings to the planning day text
      text.push(bookingsText.join("\r\n"));

      return text.length ? text.join("\r\n\r\n") : "";
    },
    incrementDays(date, numDays) {
      const incrementedDate = new Date(date.getTime());
      incrementedDate.setDate(incrementedDate.getDate() + numDays);
      return incrementedDate;
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
#cleanings-planning-card {
  .days-selector {
    min-width: 150px;
  }
}
</style>
