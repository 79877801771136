<template>
  <div class="bookings-planning-table">
    <!-- NEW BOOKING BUTTON AND SEARCH -->
    <b-row v-if="showSearch" class="mb-2 d-flex flex-wrap justify-content-end">
      <b-col cols="9" md="auto" class="mb-1 mb-md-0 justify-content-end">
        <b-form-input
          id="filterInput"
          ref="filterInput"
          v-model="filter"
          type="search"
          :placeholder="`${$t('Cerca')}...`"
          :disabled="isBusy"
          debounce="400"
          autofocus
        />
      </b-col>
    </b-row>

    <!-- BOOKINGS TABLE -->
    <b-row>
      <b-col md="12">
        <b-table
          striped
          borderless
          responsive
          class="position-relative"
          :busy.sync="isBusy"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          sort-icon-left
          :items="bookings"
          show-empty
          :empty-text="$t('errors.emptyTable.title')"
          :empty-filtered-text="$t('errors.emptyTable.title')"
          :fields="fields"
          :filter="filter"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-2" />
              <strong>{{ $t("Carregant") }}...</strong>
            </div>
          </template>

          <!-- Column: localizator -->
          <template #cell(localizator)="data">
            <b-booking-localizator
              :localizator="data.value"
              :source="data.item.source"
              :highlighted="data.item.highlighted"
              :fictitious="data.item.fictitious"
            />
          </template>

          <!-- Column: accommodation -->
          <template #cell(accommodation)="data">
            <b-accommodation
              :uuid="data.item.accommodationUuid"
              :name="data.value"
            />
          </template>

          <!-- Column: checkin -->
          <template #cell(checkin)="data">
            {{ formatBookingDate(data.item.checkin) }}
            <feather-icon icon="ArrowRightIcon" size="10" class="mx-25" />
            {{ formatBookingDate(data.item.checkout) }}
          </template>

          <!-- Column: requests -->
          <template #cell(requests)="data">
            <div class="d-flex">
              <!-- BABY COTS -->
              <div class="mr-50">
                <b-badge
                  pill
                  :variant="data.item.babyCots ? 'primary' : 'secondary'"
                >
                  <span> {{ data.item.babyCotsText }} </span>
                </b-badge>
              </div>

              <!-- BABY CHAIRS -->
              <div class="mr-50">
                <b-badge
                  pill
                  :variant="data.item.babyChairs ? 'primary' : 'secondary'"
                >
                  <span> {{ data.item.babyChairsText }} </span>
                </b-badge>
              </div>
            </div>
          </template>

          <!-- Column: providers -->
          <template #cell(providers)="data">
            {{getProvidersNames(data.item.cleaningProviders)}}
          </template>
        </b-table>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BTable,
  BRow,
  BCol,
  BFormInput,
  BSpinner,
  BBadge,
  VBTooltip,
} from "bootstrap-vue";
import BBookingLocalizator from "@foravila-core/components/b-booking-localizator/BBookingLocalizator.vue";
import BAccommodation from "@foravila-core/components/b-accommodation/BAccommodation.vue";
import { formatDateObjectToDate } from "@/utils/formatters";

export default {
  components: {
    BTable,
    BRow,
    BCol,
    BFormInput,
    BSpinner,
    BBadge,
    BBookingLocalizator,
    BAccommodation,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  props: {
    showSearch: {
      type: Boolean,
      default: () => false,
    },
    loading: {
      type: Boolean,
      default: () => false,
    },
    bookings: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isBusy: true,
      sortBy: "checkin",
      sortDesc: false,
      filter: null,
      fields: [],
      selectedBooking: null,
    };
  },
  watch: {
    loading(loading) {
      this.isBusy = loading;
    },
  },
  created() {
    this.initFields();
  },
  methods: {
    initFields() {
      this.fields.push({
        key: "localizator",
        label: this.$t("Reserva"),
        thStyle: { maxWidth: "180px" },
      });
      this.fields.push({
        key: "checkin",
        label: this.$t("Estada"),
        sortable: true,
        thStyle: { width: "225px" },
      });
      this.fields.push({ key: "accommodation", label: this.$t("Allotjament") });
      this.fields.push({ key: "client", label: this.$t("Client") });
      this.fields.push({ key: "guests", label: this.$t("Hostes") });
      this.fields.push({
        key: "checkinTime",
        label: this.$t("Hora d'entrada"),
        sortable: true,
      });
      this.fields.push({ key: "requests", label: this.$t("Peticions") });
      this.fields.push({ key: "providers", label: this.$t("Proveïdor") });
    },
    getServiceIcon(code) {
      if (!code) return "BriefcaseIcon";
      switch (code) {
        case "AIRPORT_TRANSFER":
        case "TRANSFER":
          return "StopCircleIcon";
        case "CLEANING":
        case "FINAL_CLEANING":
          return "DropletIcon";
        case "CAR_RENTAL":
          return "KeyIcon";
        case "SUPERMARKET":
          return "ShoppingCartIcon";
        default:
          return "BriefcaseIcon";
      }
    },
    formatBookingDate(date) {
      return formatDateObjectToDate(date, this.$i18n.locale);
    },
    getProvidersNames(providers) {
      console.log('getprovidersnames', providers);
      if (!providers || providers.length === 0) return "";
      return providers.map((provider) => provider.provider.fullName).join(", ");
    }
  },
};
</script>

<style lang="scss">
.bookings-planning-table {
  #per-page-input__BV_label_ {
    margin-top: 5px;
  }
}
</style>
